import { IDetailFieldConfig } from 'src/app/_metronic/shared/components/detail-list/detail-list.component';
import { ICOIInfo } from './coi.interface';
import { FileUpload, IDocument } from './document-license.interface';
import { IInspectionCompanies, IInspectionInfo, ILetterMigas } from './inspection.interface';
import { IMikrositeDetail } from './mikrosite.interface';
import { BAHistoriesStatus, IICommentCategory } from '../enums/other.enum';

export interface IApiResponseList<LIST> {
  response_schema: IResponseSchema;
  response_output: IListResponse<LIST>;
}
export interface IApiResponseDetail<DETAIL> {
  response_schema: IResponseSchema;
  response_output: IDetailResponse<DETAIL>;
}
interface IResponseSchema {
  response_code: string;
  response_message: {
    english: string;
    indonesian: string;
  };
}
export interface IListResponse<CONTENT> {
  list: {
    pagination: IPagination;
    content: CONTENT[];
  };
}
export interface IDetailResponse<CONTENT> {
  detail: CONTENT
}
export interface IPagination {
  page: number;
  size: number;
  total: number;
  total_pages: number;
}

export interface IParamsTableData {
  size?: number;
  page?: number;
  search?: string;
  sort?: string;
  name?: string | null;
}

export interface IObjectItemPick {
  [key: string]: any;
}

export interface ICheckboxOption
{ id: string; label: string; value: boolean }

export interface CardData {
  id: string;
  fields: IDetailFieldConfig[];
  customFooterId?: string;
  customTitleCardId?: string;
  title: string;
  titleStatus?: string;
  titleStatusType?: string;
  titleStatusColor?: string;
}

export interface AccordionDetail {
  name: string;
  cardsList?: CardData[];
  fields?: IDetailFieldConfig[];
}

export interface IBAHistory {
  id: number,
  file: FileUpload,
  ba_type: 'BA_FILE'|'BA_SAFETY_FILE',
  stage: 'INSPECTION'|'DISTRIBUTOR'|'EMLI',
  status_time: Date,
  status: BAHistoriesStatus,
  is_need_revision: boolean,
  is_revision_resolved: boolean,
  revision_comments: string,
  revision_time: Date,
  revision_by: string
}

export interface IInspectionDetail extends IInspectionCommentAndRemark {
  ba_file_histories: IBAHistory[],
  ba_safety_file_histories: IBAHistory[],
  appointment_letter_from_migas: ILetterMigas;
  assignment_letter_migas: ILetterMigas;
  ba_dist_sign_file?: ILetterMigas;
  ba_dist_sign_safety_file?: ILetterMigas;
  ba_emli_sign_file?: ILetterMigas;
  ba_emli_sign_safety_file?: ILetterMigas;
  ba_file?: ILetterMigas;
  ba_safety_file?: ILetterMigas;
  coi: ICOIInfo;
  coi_status: any;
  dist_sign_status: string;
  documents: IDocument[];
  emli_sign_status: string;
  id: string;
  inspection_companies: IInspectionCompanies;
  inspection_info: IInspectionInfo;
  is_active: boolean;
  mandatory: IMediaInfo[];
  microsite: IMikrositeDetail;
  po_info: PoInfo;
  optional: IMediaInfo[];
  other: IMediaInfo[];
  video: IMediaInfo[];
}

export interface IInspectionCommentAndRemark {
  is_major_comment_closed: boolean;
  is_minor_comment_closed: boolean;
  comment_major_file: FileUpload;
  comment_major_answer_file: FileUpload;
  comment_minor_file: FileUpload;
  comment_minor_answer_file: FileUpload
  remark_inspection: string;
  remark_distributor: string;
  remark_emli: string;
  minor_comments: IInspectionComment[];
  major_comments: IInspectionComment[];
  url_major: string;
  url_major_answer: string;
  url_minor: string;
  url_minor_answer: string;
}

export interface IInspectionComment {
  id?: number;
  type: 'MINOR'|'MAJOR';
  comment: string;
  answer: string;
  category: IICommentCategory;
}

export class PoInfo {
  po_code: string;
  po_coi_accelerate_ic_claim: string;
  po_invoice_date: string;
  po_invoice_no: string;
  po_invoice_value: number;
  po_number: string;
  po_progress: number;
  po_unit_rate: number;
  po_description: string;
}

export class MenuItem {
  code: string;
  url: string;
  icon: string;
  children?: MenuItem[];
  labelKey?: string;
  constructor(url: string, code: string, icon: string, labelKey: string | undefined = undefined, children: MenuItem[]|undefined = undefined) {
    this.code = code;
    this.url = url;
    this.icon = icon;
    this.labelKey = labelKey;
    this.children = children;
  }
  get menuLabelKey():string {
    return `MENU.${this.labelKey ?? this.code.toUpperCase()}`;
  }
}

export interface IFileUpload {
  id: number;
  file_id: string;
  file_name: string;
  is_active: boolean;
  file_path: string;
  created_at?: any;
  updated_at?: any;
  modified_by?: string;
  thumbnail_id?: string;
  thumbnail_path?: string;
}

export interface IMediaTicket {
  id: number;
  ticket_category: string;
  ticket_status: string;
  comment: string;
  reply_date: string;
  file_before: string;
  file_reply: string;
  distributor_comment: string;
  closed_timestamp: number;
  is_deleted: boolean;
  deleted_at: string;
  is_draft: boolean;
}

export interface IMediaInfo {
  id: number;
  media_group: string;
  title: string;
  file_upload_id?: IFileUpload;
  media_tickets?: IMediaTicket;
  file?: File;
  error?: string;
}

export interface ITerminalSupply {
  id: number,
  terminal_supply_name: string,
  code: number,
  storage_name: string,
  role_azure_id: string
}

export interface IFormErrorDisplay {
  labelKey: string;
  labelParam: any;
}

export interface IAPIResponseNotification {
  response_schema: IResponseSchema;
  response_output: INotifications;
}

export interface INotifications {
  list: {
    pagination: IPagination;
    content: INotification[];
    unread_count: number,
  };
}

export interface INotification {
  id: number,
  is_read: boolean,
  read_at: Date,
  html_content_en: string,
  html_content_id: string,
  action_text_en: string,
  action_text_id: string,
  deep_link: string,
  created_at: Date
}

export interface IUploadFileTerminalReceiveLifting {
  tank_id: any,
  tank_code: any,
  tank_data: any[],
  terminal_id: any,
  month: any,
  year: any
}