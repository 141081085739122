import { MenuItem } from '../interfaces/common.interface';

export class AppConstants {
  static ROLE_ACCESS_TYPE = ['Create', 'View', 'Update', 'Delete'];
  static MIKROSITE_TABS = ['Submitted', 'Draft', 'Reject', 'Approve'];
  static EXCEL_DATE_FORMAT = 'DD/MM/yyyy';
  static DEFAULT_DATE_DISPLAY_FORMAT = 'dd/MM/yyyy';
  static DATE_WITH_HOUR = 'dd/MM/yyyy - HH:mm';
  static MEDIA_STATUS = 'MediaStatus';
  static ADDITIONAL_VIDEO = 'COMMON.ADDITIONAL_VIDEO';
  static ERROR_MEDIA_EMPTY = 'ERROR.MEDIA_EMPTY';

  static WELCOME_URL = 'welcome';

  static Modules: { [key: string]: MenuItem } = {
    DASHBOARD: new MenuItem(
      'dashboard',
      'dashboard',
      'icon-med-device-tv',
      'DASHBOARD',
      [
        new MenuItem(
          'welcome',
          'welcome',
          'icon-med-device-tv',
          'WELCOME'
        ),
        new MenuItem(
          'dashboard-inspection-tracker',
          'dashboard_inspection_tracker',
          'icon-med-device-tv',
          'INSPECTION_TRACKER'
        ),
        new MenuItem(
          'dashboard-cso-terminal',
          'dashboard_cso_terminal',
          'icon-med-device-tv',
          'CSO_TERMINAL'
        ),
        new MenuItem(
          'dashboard-skp-distributor',
          'dashboard_skp_distributor',
          'icon-med-device-tv',
          'SKP_DISTRIBUTOR'
        )
      ]
    ),
    ACCESS_MANAGEMENT: new MenuItem(
      'access-management',
      'access_management',
      'icon-med-table',
    ),
    MICROSITE: new MenuItem('microsite', 'microsite', 'icon-med-gas-pump'),
    DOCUMENT_LICENSE: new MenuItem(
      'document-license',
      'document_license',
      'icon-med-file-generic'
    ),
    LIST_TO_ASSIGN: new MenuItem(
      'list-to-assign',
      'microsite_list_to_assign',
      'icon-list-to-assign',
      'LIST_TO_ASSIGN'
    ),
    INSPECTION: new MenuItem('inspection', 'inspection', 'icon-inspection-company'),
    SIGN_BA_INSPECTION_DIST: new MenuItem(
      'sign-ba-inspection-dist',
      'sign_ba_inspection_dist',
      'icon-sign-ba-dist'
    ),
    SIGN_BA_INSPECTION_EMLI: new MenuItem(
      'sign-ba-inspection-emli',
      'sign_ba_inspection_emli',
      'icon-sign-ba-emli'
    ),
    COI: new MenuItem('coi', 'coi', 'icon-coi'),
    COI_RENEWAL: new MenuItem(
      'coi-renewal',
      'coi_renewal',
      'icon-renewal-document',
      'COI_RENEWAL',
    ),
    TERMINAL_LIFTING_REPORT: new MenuItem(
      'terminal-lifting-report',
      'terminal_lifting',
      'icon-terminal-lifting-report',
      'TERMINAL_LIFTING_REPORT'
    ),
    TERMINAL_RECEIVE_REPORT: new MenuItem(
      'terminal-receive-report',
      'terminal_receive',
      'icon-terminal-receive-report',
      'TERMINAL_RECEIVE_REPORT'
    ),
    PREPARE_INSPECTION_BOOK: new MenuItem(
      'prepare-inspection-book',
      'prepare_inspection_book',
      'icon-prep-insp-book'
    ),
    DATA_MONITORING: new MenuItem(
      'data-monitoring',
      'data_monitoring',
      'icon-data-monitoring'
    ),
    DATA_MONITORING_MIGAS: new MenuItem(
      'data-monitoring-migas',
      'data_monitoring_migas',
      'icon-data-monitoring'
    ),
    DG_MIGAS: new MenuItem(
      'dg-migas-reporting',
      'dg_migas_reporting',
      'icon-dg-migas-report',
      'DG_MIGAS'
    ),
    REPORT_BUILDER: new MenuItem(
      'report-builder',
      'report_builder',
      'icon-med-file-generic',
      'REPORT_BUILDER',
      [
        new MenuItem(
          'report-builder-distributor',
          'report_builder_distributor',
          'icon-med-file-generic',
          'REPORT_BUILDER_DISTRIBUTOR'
        ),
        new MenuItem(
          'report-builder-cso',
          'report_builder_cso',
          'icon-med-file-generic',
          'REPORT_BUILDER_CSO'
        ),
        new MenuItem(
          'report-builder-inspection',
          'report_builder_inspection',
          'icon-med-file-generic',
          'REPORT_BUILDER_INSPECTION'
        ),
      ]
    ),
    INSPECTION_BOOK_MIGAS: new MenuItem(
      'inspection-book-migas',
      'inspection_book',
      'icon-insp-book',
      'INSPECTION_BOOK_MIGAS'
    ),
  };
}

export const TenMB: number = 10 * 1024 * 1024;
export const FiftyMB: number = TenMB * 5;
export const HundredMB: number = TenMB * 10;
export const TwoHundredMB: number = HundredMB * 2;
